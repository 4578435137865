<template>
  <iframe class="iframe" :src="iframeHref" scrolling="no"/>
</template>

<script>
import {
  mapState,
  mapMutations
} from "vuex";
import store from "@/store";
import router from "../router";
import {
  Message,
  Notice
} from "view-design";
import dataReports from "@/mixins/dataReports";

export default {
  name: "myIframe",
  components: {},
  mixins: [dataReports],
  data() {
    return {
      iframeHref: "",
      isShow: true, // 是否显示iframe
    };
  },
  watch: {
    $route() {
      //拼接ifame的路由
      this.changeIframeSrc()
      //企业板块多iframe特殊处理
      if(this.$route.path.indexOf("enterprise")>0){
        this.leavePageLog()
        this.enterPagelog()
      }
    },
    /*iframeHref() {
      //企业板块多iframe特殊处理
      if(this.$route.path.indexOf("enterprise")>0){
        this.leavePageLog()
        this.enterPagelog()
      }
    }*/
  },
  computed: {
    ...mapState(["iframeUrl", "reportAuthIds", "iframeData"]),
  },
  beforeRouteEnter(to, from, next) {
    const iframeHref = localStorage.getItem("iframeUrl");
    if (!iframeHref) {
      next((vm) => {
        vm.changeState({
          prop: "iframeUrl",
          value: "",
        });
        vm.$router.push({
          path: from.path
        });
      });
    }
    const iframeItem = store.state.iframeData.find((item) =>
        iframeHref.includes(item.fineReportUrl)
    );
    if (
        !Array.isArray(store.state.reportAuthIds) ||
        !store.state.reportAuthIds.includes(iframeItem.id)
    ) {
      Notice.warning({
        title: "温馨提示",
        desc: `${this.noticeInfo.noAuthority[1002]}`,
        duration: 10,
      });
      next((vm) => {
        vm.changeState({
          prop: "iframeUrl",
          value: "",
        });
        vm.$router.push({
          path: from.path
        });
      });
      return;
    }
    next();
  },

  methods: {
    ...mapMutations(["changeState"]),
    changeIframeSrc() {
      if (this.$route.path.indexOf("iframe") > 0) {
        //拼接iframe的src
        let iframeData = JSON.parse(localStorage.getItem("iframeData"))
        let rPath = "";
        for (const element of iframeData) {
          if (this.$route.meta.indexName && this.$route.meta.indexName == element.indexName) {
            rPath = element.fineReportUrl
            this.activeName = this.$route.path + ""
            this.iframeHref =  location.protocol + "//" +
                location.host +rPath;
            this.changeState({
              prop: "iframeUrl",
              value:
                  this.iframeHref,
            });
            localStorage.setItem(
                "iframeUrl",
                this.iframeHref
            );
            break
          }
        }
      }
    }
  },
  beforeMount() {
    this.$Notice.destroy();
  },
  mounted() {
    this.iframeHref = this.iframeUrl || localStorage.getItem("iframeUrl");
    this.changeState({
      prop: "iframeUrl",
      value: "",
    });
  },
};
</script>
<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
